<template>
	<Layout>
		<Row class="bg-white">
			<Container>
				<img
					src="/images/404.jpg"
					class="max-w-[620px] w-full mx-auto mb-[32px]"
				/>
				<Heading
					tag="h2"
					class="text-center font-semibold mb-[16px]"
					text="OOPS! YOU BROKE THE INTERNET"
				/>

				<p class="text-lg text-center mb-[32px]">
					You may have mistyped the address or the page may have been moved.
				</p>

				<Button
					label="Go To Homepage"
					class="mx-auto"
					type="link"
					to="/"
				/>
			</Container>
		</Row>
		<Row v-if="posts">
			<Container>
				<Heading
					tag="h3"
					class="text-center font-semibold mb-[16px]"
					text="CHECK OUT THE LATEST ARTICLES FROM THE BLOG"
				/>

				<section
					class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[32px] px-[16px] md:px-[32px] xl:px-0"
				>
					<PostTile
						v-for="post in posts"
						:key="post.id"
						:content="post.content.rendered"
						:source-url="
							post._embedded['wp:featuredmedia'] &&
							post._embedded['wp:featuredmedia'][0]
								? post._embedded['wp:featuredmedia'][0].source_url
								: null
						"
						:title="post.title.rendered"
						:slug="post.slug"
						:category="post.categories[0]"
					/>
				</section>
			</Container>
		</Row>
	</Layout>
	<Head>
		<Title>Page Not Found - Timothy Sykes</Title>
		<Meta
			name="description"
			content="Timothy Sykes is a millionaire penny stock trader and entrepreneur. He is best known for earning $1.65 million by day trading while attending Tulane University."
		/>
	</Head>
</template>

<script setup>
import Layout from "~/layouts/default.vue";
import { Container, Row, PostTile, Heading, Button } from "#components";

const router = useRoute();
// eslint-disable-next-line
defineProps(["error"]);

const config = useRuntimeConfig();

const API_ENDPOINT = `${config.public.hostname}wp-json/wp/v2/posts/`;

const { data: posts } = await useAsyncData("posts_list", async () => {
	const { data } = await fetchPostsList(API_ENDPOINT);

	return data;
});

watch(
	() => router.path,
	(newPath) => {
		if (process.client && newPath !== "") {
			window.location.href = newPath;
		}
	}
);
</script>

<style scoped>
.site-container {
	background-color: #262930;
	background-size: inherit;
	background-repeat: no-repeat;
	background-position-x: 66%;
	background-position-y: -20%;
	color: #fff;
	width: auto;
}
.nav-404 {
	padding-top: 4rem;
}
.nav-404 span {
	color: #fff;
	font-size: 1.5rem;
	font-weight: 700;
}
.nav-404 span:hover {
	color: #3094fb;
}
.nav-404 a:hover {
	text-decoration: none;
	text-transform: none;
}

.content-404 {
	padding: 65px 0;
	margin-bottom: 32px;
}
.content-404 h1 {
	font-size: 150px;
	font-weight: 400;
}
.content-404 h5 {
	font-size: 20px;
	color: #fff;
	width: 100%;
	font-weight: 400;
}
.content-404 .blue-gradient-big {
	padding: 20px;
	margin-top: 50px;
}
.site-footer {
	padding-top: 25rem;
	border-top: none;
}
@media only screen and (min-width: 768px) {
	.content-404 h1 {
		font-size: 200px;
	}
}
.row {
	padding: 32px 0 !important;
}
@media only screen and (min-width: 1200px) {
	.row {
		padding: 64px 0 !important;
	}
}
</style>
